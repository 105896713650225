import { ApplicationRef, Inject, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomePageModule } from './homePage/homePage.module';
import { EventsModule } from './events/events.module';
import { SharedModule } from './shared/shared.module';
import { CeuTrackerAdminModule } from './ceuTrackerAdmin/ceuTrackerAdmin.module';
import { CredentialHoldersModule } from './credentialHolders/credentialHolders.module';
import { PublicationsModule } from './publications/publications.module';
import { SearchModule} from './search/search.module';

import { FocusAreaFilterComponent } from './shared/focusAreaFilter.component';
import { CredentialHoldersComponent } from './credentialHolders/credentialHolders.component';
import { SortByPipe } from './shared/sortBy.pipe';
import { ArticleWidgetComponent } from './homePage/articleWidget.component';
import { EventWidgetComponent } from './homePage/eventWidget.component';
import { ArticleListComponent } from './publications/articleList.component';
import { PublicationFilterComponent } from './publications/publicationFilter.component';
import { PublicationIssueComponent } from './publications/publicationIssue.component';
import { BlogComponent } from './publications/blog.component';
import { SearchComponent } from './search/search.component';
import { CEUTrackerAdminComponent } from './ceuTrackerAdmin/ceuTrackerAdmin.component';
import { EventsComponent } from './events/events.component';
import { PaymentModule } from './payment/payment.module';
import { UserImportReportModule } from './userimportreport/userimportreport.module';
import { PassFailReportModule } from './passfailreport/passfailreport.module';

import ahccApp from './app.module.ajs';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        UpgradeModule,
        HttpClientModule,
        NgbModule,
        SharedModule,
        HomePageModule,
        CeuTrackerAdminModule,
        EventsModule,
        CredentialHoldersModule,
        PublicationsModule,
        SearchModule,
        PaymentModule,
        UserImportReportModule,
        PassFailReportModule
    ],
    providers: [
    ],
    declarations: [
    ],
    entryComponents: [
        ArticleWidgetComponent,
        EventWidgetComponent,
        PublicationIssueComponent,
        ArticleListComponent,
        FocusAreaFilterComponent,
        PublicationFilterComponent,
        PublicationIssueComponent,
        BlogComponent,
        CredentialHoldersComponent,
        CEUTrackerAdminComponent,
        SearchComponent,
        EventsComponent,
    ],

})

export class AppModule {

    constructor(@Inject(Injector) injector: Injector) {
        const articleWidget = createCustomElement(ArticleWidgetComponent, { injector: injector });
        customElements.define('article-widget', articleWidget);
        const eventWidget = createCustomElement(EventWidgetComponent, { injector: injector });
        customElements.define('event-widget', eventWidget);
        const blogComponent = createCustomElement(BlogComponent, { injector: injector });
        customElements.define('blog-component', blogComponent);
        const articleList = createCustomElement(ArticleListComponent, { injector: injector });
        customElements.define('article-list', articleList);
        const focusAreaFilter = createCustomElement(FocusAreaFilterComponent, { injector: injector });
        customElements.define('focus-area-filter-component', focusAreaFilter);
        const publicationFilter = createCustomElement(PublicationFilterComponent, { injector: injector });
        customElements.define('publication-filter', publicationFilter);
        const publicationIssue = createCustomElement(PublicationIssueComponent, { injector: injector });
        customElements.define('publication-issue', publicationIssue);
        const credentialHolders = createCustomElement(CredentialHoldersComponent, { injector: injector });
        customElements.define('credential-holders', credentialHolders);
        const ceuAdmin = createCustomElement(CEUTrackerAdminComponent, { injector: injector });
        customElements.define('ceu-admin', ceuAdmin);
        const searchResults = createCustomElement(SearchComponent, { injector: injector });
        customElements.define('search-results', searchResults);
        const eventsComponent = createCustomElement(EventsComponent, { injector: injector });
        customElements.define('events-comp', eventsComponent);
    }

    ngDoBootstrap() {
        console.log('app boot');
    }

}
