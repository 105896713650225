import { Injectable, Inject, OnInit } from '@angular/core';
import { IPaymentInfo } from './payment.model';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(@Inject(HttpClient) private http: HttpClient) {}

    submitOrder(info:IPaymentInfo): Promise<any> {

        const url: string = '/umbraco/api/PaymentApi/SubmitOrder';
        const json: string = JSON.stringify({ CartId: info.CartId, CreditCardType: info.CreditCardType, CardholderName: info.CardholderName, CreditCardNumber: info.CreditCardNumber, CreditCardExpMonth: info.CreditCardExpMonth, CreditCardExpYear: info.CreditCardExpYear, CreditCardCVC: info.CreditCardCVC, CreditCardZip: info.CreditCardZip });
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, json, httpOptions).toPromise();
    }

    createOrder(candidateId:string,examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/CreateOrder?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    submitBilling(bill: any): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/SubmitBilling';
        const json: string = JSON.stringify({ CartId: bill.cartId, CandidateId: bill.candidateId, BillingFirstName: bill.firstName, BillingLastName: bill.lastName, BillingStreet: bill.addr1 + " " + bill.addr2, BillingCity: bill.city, BillingState: bill.state, BillingZip: bill.zip, BillingPhone: bill.phone});
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, json, httpOptions).toPromise();
    }

    productDetails(examcode: string, candidateId: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/GetProductDetails?examcode=' + examcode + "&candidateId=" + candidateId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }

    paymentTypes(cartId: number): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/GetPaymentMethods?cartId=' + cartId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }
    isRecert(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/IsRecert?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }
    isAda(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/IsADA?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }

    deferBilling(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/DeferBilling?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    deferAda(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/DeferADA?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    setPending(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/SetPending?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    recertify(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/Recertify?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    sendConfirmationEmail(candidateId: string, examcode: string, emailType: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/SendEmail?candidateId=' + candidateId + '&examBaseCode=' + examcode + "&emailType=" + emailType;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    getPrometricScheduleLink(candidateId: string, examcode: string) {
        const url: string = '/umbraco/api/PrometricApi/ScheduleLinkTemplate?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }

    submitXelig(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PrometricApi/SubmitEligibility?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    confirmPayment(candidateId: string, examcode: string): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/UpdateBalance?candidateId=' + candidateId + '&examcode=' + examcode;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
}